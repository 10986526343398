import { useContext } from "react";
import { productDetailsContext } from "../ProductDetailsContext";
import { DownloadAll } from "components";
import { useIntl } from "react-intl";
import { LocalizationKey, UserType } from "helpers/Enums";
import { applicationContext } from "helpers/services";


const LeftColumn = () => {
    const { formatMessage } = useIntl();
    const service = useContext(productDetailsContext);

    const { currentUser } = useContext(applicationContext);


    return (
        <>
            <div className="carousel-inner">
                {service.selectedImage !== null &&
                    <div className="item gallery active">
                        <a
                            rel="prettyPhoto[gal]"
                            title={service.selectedImage.title}
                            href={`${process.env.REACT_APP_MEDIA_CDN}/${service.selectedImage.mediaId}?width=1000`}
                        >
                            <img
                                alt=""
                                className="img-responsive"
                                title={service.selectedImage.title}
                                src={`${process.env.REACT_APP_MEDIA_CDN}/${service.selectedImage.mediaId}?heigth=490&width=600`}
                            />
                        </a>
                    </div>
                }
                {service.productDetails.images.length !== 0 &&
                    <br />
                }

                <ul className="list-inline">
                    {service.productDetails.images.map(image =>
                        <li onClick={() => service.handleSelectedImage(image)} key={image.mediaId}>
                            <div className="pictureBig text-center">
                                <img src={`${process.env.REACT_APP_MEDIA_CDN}/${image.mediaId}?heigth=490&width=490`} />
                                <a
                                    className="d-none"
                                    rel="prettyPhoto[gal]"
                                    href={`${process.env.REACT_APP_MEDIA_CDN}/${image.mediaId}?width=1000`}
                                />
                            </div>
                        </li>
                    )}
                    {currentUser.userType === UserType.External
                        && service.productDetails.images.length > 1
                        && currentUser.hasSalesforceIntegration === true &&
                        <DownloadAll
                            component="li"
                            linkedTo="Product"
                            id={service.productDetails.itemId}
                        />
                    }
                </ul>
            </div>
            <div className="details__container">
                <div className="row no-gutters">
                    {service.productDetails.spareParts.length !== 0 &&
                        <div className="col-media__multiple-files--small">
                            <h4 className="pb-0 mb-0">
                                <span>Spare Parts</span>
                            </h4>
                            <div className="typegroup">
                                {service.productDetails.spareParts.map(sparePart =>
                                    <div className="typegroup__element" key={sparePart.mediaId}>
                                        <div className="typegroup__element__file">
                                            <div className="file-type file-pdf">
                                            </div>
                                        </div>
                                        <div className="typegroup__element__text">
                                            <strong>{sparePart.title}</strong>
                                            <small>{sparePart.fileName}</small>
                                        </div>
                                        <div className="typegroup__element__links">
                                            <a
                                                className="download"
                                                target="_blank"
                                                href={`${process.env.REACT_APP_MEDIA_CDN}/${sparePart.mediaId}`}
                                            >
                                                <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.Download })}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                    {service.productDetails.manuals.length !== 0 &&
                        <div className="col-media__multiple-files--small" />
                    }
                    {service.productDetails.manuals.length !== 0 &&
                        <div className="col-media__multiple-files--small">
                            <h4 className="pb-0 mb-0">
                                <span>Manuals</span>
                            </h4>
                            <div className="typegroup">
                                {service.productDetails.manuals.map(sparePart =>
                                    <div className="typegroup__element" key={sparePart.mediaId}>
                                        <div className="typegroup__element__file">
                                            <div className="file-type file-pdf">
                                            </div>
                                        </div>
                                        <div className="typegroup__element__text">
                                            <strong>{sparePart.title}</strong>
                                            <small>{sparePart.fileName}</small>
                                        </div>
                                        <div className="typegroup__element__links">
                                            <a
                                                className="download"
                                                target="_blank"
                                                href={`${process.env.REACT_APP_MEDIA_CDN}/${sparePart.mediaId}`}
                                            >
                                                <i className="fa fa-download" /> {formatMessage({ id: LocalizationKey.Download })}
                                            </a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default LeftColumn;