import { LocalizationKey } from "helpers/Enums";

let enUS = {
    /*  ERROR 403  */
    [LocalizationKey.Error403]: "Something is wrong with your user",
    [LocalizationKey.Error403Note]: "Try to logout and login again: if nothing change ask for technical support.",

    /*  ERROR 404  */
    [LocalizationKey.Error404]: "404: The page you are looking for isn’t here",
    [LocalizationKey.Error404Note]: "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation",
    
    /*  GENERAL  */
    [LocalizationKey.Hi]: "Hi",
    [LocalizationKey.Logout]: "Logout",
    [LocalizationKey.PrivacyLink]: "https://www.videndummediasolutions.com/en/privacy-policy/",
    [LocalizationKey.DownloadError]: "An error occurred while processing your request. Refresh this page to try again",
    [LocalizationKey.DownloadSuccess]: "Your request has been processed successfully",
    [LocalizationKey.DownloadInProgress]: "Your request is in progress",
    [LocalizationKey.RequestSent]: "Request sent",
    [LocalizationKey.OurBrands]: "Our brands",
    [LocalizationKey.NoResultsFound]: "No results found",
    [LocalizationKey.Markets]: "Markets",
    [LocalizationKey.Brand]: "Brand",
    [LocalizationKey.Brands]: "Brands",
    [LocalizationKey.ViewMore]: "View more",
    [LocalizationKey.Products]: "Products",
    [LocalizationKey.DownloadAllImages]: "Download all images",
    [LocalizationKey.DownloadAllImagesNotes]: "We'll send a ZIP file to your email address {emailAddress}",
    [LocalizationKey.Download]: "Download",
    [LocalizationKey.ProductDetail]: "Product detail",
    [LocalizationKey.PhoneNumber]: "+39 0424 555855",
    [LocalizationKey.MarketingTools]: "Marketing tools",
    [LocalizationKey.Search]: "Search",
    [LocalizationKey.Any]: "Any",
    [LocalizationKey.Previous]: "Previous",
    [LocalizationKey.Next]: "Next",
    [LocalizationKey.Close]: "Close",
    [LocalizationKey.Confirm]: "Confirm",
    [LocalizationKey.Page]: "Page",
    [LocalizationKey.Of]: "of",

    /*  HOMEPAGE  */
    [LocalizationKey.HomepageWelcomeTo]: "WELCOME TO VIDENDUM MEDIA DISTRIBUTION",
    [LocalizationKey.HomepageWelcomeAnonymus]: "Welcome to the {videndumBold}, a dedicated space to our official Distributors network.\nLog in to get access to all the {productInfoBold} and {marketingToolsBold} needed to sell effectively our products, and all the latest news from Manfrotto world.",
    [LocalizationKey.HomepageWelcomeAuthenticated]: "To get the most out of all the resources and services available please look at the{link}\nWe hope you will find this mini-site useful.",
    [LocalizationKey.HomepageAboutUs]: "ABOUT US",
    [LocalizationKey.HomepageAboutUsLine1]: "Videndum Media Solutions designs, manufactures and distributes premium branded photographic and video equipment such as tripods, bags, filters and lights for professional and consumer photographers and videographers.",
    [LocalizationKey.HomepageAboutUsLine2]: "Videndum Media Solutions products are sold by Videndum Media Distribution, in China, France, Germany, Hong Kong, Italy, Benelux, Japan, the United Kingdom, and the United States, plus a qualified independent distributors network in over 65 countries around the world. The unrivalled strength of the international distribution network and the team efforts between the company and the distributors is a key element in the success of the lines.",
    [LocalizationKey.Videndum]: "Videndum Media Distributors Area",
    [LocalizationKey.ProductInformation]: "Product information",
    [LocalizationKey.HomepageWelcomeMarketingTools]: "Marketing tools",
    
    /*  MARKETING TOOLS  */
    [LocalizationKey.HomepageMarketingTools]: "Marketing Tools",
    [LocalizationKey.Filters]: "Filters",
    [LocalizationKey.SearchByTitle]: "Search by title or sku...",
    [LocalizationKey.Category]: "Category",
    [LocalizationKey.Year]: "Year",
    [LocalizationKey.MarketingToolsFound]: "Marketing tools found",
    [LocalizationKey.ProductsInMarketingTool]: "Products in this Marketing Tool",
    [LocalizationKey.ShowAll]: "Show all",
    [LocalizationKey.ShowLess]: "Show less",
    [LocalizationKey.BackToSearch]: "Back to search",
    [LocalizationKey.OpenInVimeo]: "Open in vimeo",

    /*  PRODUCT DATA  */
    [LocalizationKey.ProductData]: "Product data",
    [LocalizationKey.ProductDataNotes]: "This section is designed to provide you with the technical data and specifications for all Videndum Media Solutions products.\nIf you wish to open your own online shop, generate automatic procedures or price lists for managing your e-commerce activities, you’ll find all the resources you need here.\nThis information is provided in Excel files in two different formats (JSON and XML files), for easier, more flexible use.",
    [LocalizationKey.ProductDataJsonInfo]: "Is a minimal, readable format for structuring data. It is used primarily to transmit data between a server and web application, as an alternative to XML.\nSquarespace uses JSON to store and organize site content created with the CMS.",
    [LocalizationKey.ProductDataXmlInfo]: "Is a markup language that defines a set of rules for encoding documents in a format which is both human-readable and machine-readable. The design goals of XML emphasize simplicity, generality and usability across the Internet. It is a textual data format with strong support via Unicode for different human languages.",
    [LocalizationKey.ProductImages]: "Product Images (zip file)",

    /*  VISUAL PRICE LIST  */
    [LocalizationKey.VisualPriceList]: "Visual price list",
    [LocalizationKey.ProductCode]: "Product code",
    [LocalizationKey.ProductDescription]: "Product description",
    [LocalizationKey.ViewBy]: "View by",
    [LocalizationKey.Found]: "Found",
    [LocalizationKey.Product]: "Product",
    [LocalizationKey.MasterCarton]: "Master carton",
    [LocalizationKey.SuggestedPrice]: "Suggested price",
    [LocalizationKey.Loading]: "Loading...",
    [LocalizationKey.TechnicalSpecifications]: "Technical specifications",
    [LocalizationKey.Specification]: "Specification",
    [LocalizationKey.Value]: "Value",
    [LocalizationKey.CountryOfOrigin]: "Country of origin",
}

export default enUS;