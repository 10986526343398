import { Typography, Container } from '@mui/material';

const Error401 = (props: any) => {

  return (
    <Container maxWidth="lg" style={{ textAlign: 'center' }}>
      <Typography align="center" variant="h2" sx={{ mt: 7 }}>
        401: Sorry, we're unable to authenticate you
      </Typography>
      <Typography align="center" variant="subtitle2" sx={{ mb: 8.2 }}>
        You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation
      </Typography>
      <img alt="Under development" src="/images/errors/401.svg" height={500} />
    </Container>
  );
};

export default Error401;
